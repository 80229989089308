import React, { useState, useEffect } from "react";
import Breadcum from "../breadcrumb";
import AboutTabs from "../AboutTabs";

const CoreTeam = () => {
  const [coreTeamContent, setCoreTeamContent] = useState([]);

  useEffect(() => {
    const slug = window.location.pathname.split('/').filter(Boolean).pop();
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages?slug=' + slug + '&_embed';

    fetch(endpointUrl)
      .then(response => response.json())
      .then(data => {
        const coreTeamData = data[0].core_team_data;
        const coreTeamImageIds = data[0].core_team_image;

        // Fetch the image URLs using the `/media` endpoint for each core team image ID
        const fetchImageUrls = coreTeamImageIds.map(async (imageId) => {
          try {
            const response = await fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`);
            const imageData = await response.json();
            let altText = imageData.alt_text || ''; // Initialize alt text with empty string

            // If alt text is not available, use the title as alt text
            if (!altText) {
              altText = imageData.title.rendered || ''; // Use title as alt text
            }

            return {
              image: imageData.source_url,
              altText: altText,
            };
          } catch (error) {
            console.error(error);
            return null;
          }
        });

        // Wait for all image URL requests to complete before updating state
        Promise.all(fetchImageUrls)
          .then((imageDataArray) => {
            const coreTeam = coreTeamData.map((content, index) => {
              const imageData = imageDataArray[index];
              return {
                image: imageData ? imageData.image : '', // Use an empty string if image data is not available
                altText: imageData ? imageData.altText : '', // Use an empty string if alt text is not available
                content: content,
              };
            });

            setCoreTeamContent(coreTeam);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Breadcum />
      <AboutTabs />
      <section className="about_wrapper sp">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_main">
                <div className="page_main_title">
                  <h2>Core Team</h2>
                </div>
                <div className="row">
                  {coreTeamContent.map((member, index) => (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" key={index}>
                      <div className="faculty_item">
                        <div className="faculty_image">
                          <img src={member.image} alt={member.altText} />
                        </div>
                        <div className="faculty_content">
                          <div dangerouslySetInnerHTML={{ __html: member.content }}></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CoreTeam;
