import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HomeIndustries = () => {
  const [homeIndustriesContent, setHomeIndustriesContent] = useState([]);

  useEffect(() => {
    fetch('https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/133?_embed')
      .then(response => response.json())
      .then(data => {
        const imageIds = data.industry_image;
        const industryNames = data.industry_name;

        Promise.all(
          imageIds.map(imageId =>
            fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`)
              .then(response => response.json())
              .then(imageData => {
                const imageUrl = imageData.source_url;
                const altText = imageData.alt_text || imageData.title.rendered;
                return { imageUrl, altText };
              })
              .catch(error => {
                console.error(`Failed to fetch image for industry with ID ${imageId}: ${error}`);
                return null;
              })
          )
        ).then(imagesWithAlt => {
          const industries = imagesWithAlt.map((imageWithAlt, index) => ({
            image: imageWithAlt.imageUrl,
            altText: imageWithAlt.altText || industryNames[index], // Use image title if alt text is not available
            content: industryNames[index],
          }));
          setHomeIndustriesContent(industries);
        });
      })
      .catch(error => console.error(error));
  }, []);

  const owlCarouselOptions = {
    items: 6,
    loop: true,
    nav: false,
    margin: 30,
    smartSpeed: 2000,
    dots: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      576: {
        items: 3,
        margin: 20,
      },
      768: {
        items: 3,
        margin: 30,
      },
      992: {
        items: 4,
        margin: 30,
      },
      1200: {
        items: 5,
        margin: 30,
      },
    },
  };

  return (
    <section className="home_industries sp">
      <div className="container">
        <div className="industries_main_wrapper">
          <div className="main_title">
            <h2>Industry Sectors Coverage</h2>
          </div>
          <p>We cater as per your sector logistics needs.</p>
          <div className="industries_carousel_main">
            {homeIndustriesContent.length > 0 ? (
              <OwlCarousel {...owlCarouselOptions}>
                {homeIndustriesContent.map((industryItem, index) => (
                  <div key={index}>
                    <div className="industries_item">
                      <div className="industries_icon">
                        {industryItem.image && (
                          <img src={industryItem.image} alt={industryItem.altText} />
                        )}
                      </div>
                      <h3>{industryItem.content}</h3>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <div>No industries available.</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIndustries;
