import Header from "../header";
import Herosection from '../hero-section';
import Homeoffer from '../what-we-offer';
import HomeChooseSection from '../home-why-choose';
import HomeVision from '../mission-vision';
import HomeIndustries from '../home-industries';
import HomeNetworks from '../home-networks';
import Homeclients from '../home-clients';
import Hometestimonial from '../home-testimonial';
import Footer from '../footer';
import TrackShipment from '../track-shipment';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Homepage = () => {
    return (
     <>
     <Herosection/>
        <TrackShipment/>
        <Homeoffer/>
        <HomeIndustries/>
        <HomeVision/>
        <HomeChooseSection />
        <HomeNetworks/>
        <Hometestimonial/>
        <Homeclients/>
        
     </>
    )
  }
  export default Homepage;