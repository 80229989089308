import React from "react";
import Header from "../header";
import Footer from "../footer";
import { useState, useEffect } from 'react';
import axios from 'axios';
import Breadcum from "../breadcrumb";
import { BrowserRouter, Route, Routes , Link, Form} from 'react-router-dom';
import AboutTabs from "../AboutTabs";
import Careerform from "../Career-form";

const Career = () => {
   

    
   // console.log('overview top image url:',overviewTopImageUrl);
  //  console.log('overview bottom image url:',overviewBottomImageUrl);
    
//console.log('ResSrc', ResponseSrc);

    return (
        <>
            <Breadcum/>
            <Careerform/>
        </>
    )
}

export default Career;
