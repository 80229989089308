    import React from 'react';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
    import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
    import { faYoutube } from "@fortawesome/free-brands-svg-icons";
    import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
    import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
    import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
    import { useEffect } from 'react';
    import { useState } from 'react';



    function Footer() {
        const [mobileData, setMobileData] = useState([]);
        const [emailData, setEmailData] = useState([]);
    


        useEffect(() => {
            // Replace this with your API call or data source
            fetch('https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/459')
                .then((response) => response.json())
                .then((data) => {
                    // Extract mobile data
                    if (data.mobile && data.mobile.length > 0) {
                        setMobileData(data.mobile.map((item, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                        )));
                    }
    
                    // Extract email data
                    if (data.email && data.email.length > 0) {
                        setEmailData(data.email.map((item, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                        )));
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }, []);
        
        return (
            <>
                <footer className='footer_main_wrapper sp'>
                    <div className='container'>
                        <Row>
                            <Col>
                                <div className='footer_col'>
                                    <div className='footer_logo'>
                                        <img src={process.env.PUBLIC_URL + "/images/footer-logo.png"} alt="Acare Logo" />
                                    </div>
                                    <div className='social_icons'>
                                        <ul>
                                            <li>
                                                <a href='#.'>
                                                    <div className='social_icon'>
                                                        <FontAwesomeIcon icon={faFacebookF} />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#.'>
                                                    <div className='social_icon'>
                                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#.'>
                                                    <div className='social_icon'>
                                                        <FontAwesomeIcon icon={faYoutube} />
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='footer_col'>
                                    <h3>Our services</h3>
                                    <span></span>
                                    <ul>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/services/acare-express"}>
                                                Acare Express
                                            </a>
                                        </li>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/services/3pl-transport-management"}>
                                                3PL Activities
                                            </a>
                                        </li>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/services/acare-warehousing"}>
                                                Acare Warehousing
                                            </a>
                                        </li>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/services/acare-premium"}>
                                                Acare Premium
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col>
                                <div className='footer_col'>
                                    <h3>Quick Links</h3>
                                    <span></span>
                                    <ul>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/about-us"}>
                                                About Us
                                            </a>
                                        </li>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/services"}>
                                                Our Services
                                            </a>
                                        </li>
                                    
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/career"}>
                                                Careers
                                            </a>
                                        </li>
                                        <li>
                                            <a href={process.env.PUBLIC_URL + "/contact-us"}>
                                                Contact Us
                                            </a>
                                        </li>
                                        <li>
                                            <a href='http://122.160.33.112/ACARETracking/' target='_blank'>
                                                Track Your shipment
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col>
                                <div className='footer_col last_col'>
                                    <h3>Get in Touch</h3>
                                    <span></span>
                                    <ul>
                                        <li>
                                            <div className='icon'>
                                                {/* <img src={process.env.PUBLIC_URL+"/images/call.webp"}/> */}
                                                <FontAwesomeIcon icon={faPhoneAlt} />
                                            </div>
                                            <div>
                                            {mobileData}
                                            </div>

                                        </li>
                                        <li>
                                            <div className='icon'>
                                                {/* <img src={process.env.PUBLIC_URL+"/images/mail.webp"}/> */}
                                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                                            </div>
                                            {emailData}
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='cpoyright'>
                                    <p>Copyright ©{new Date().getFullYear()} Acare Logistics Solution pvt. Ltd. All Rights Reserved.</p>
                                    <p>Developed By <a href="">Abacus Desk</a></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </footer>




            </>
        )
    }
    export default Footer;