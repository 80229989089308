import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, Link, Form } from 'react-router-dom';
import React from 'react';
import '../css/main-style.css';
import '../fonts/stylesheet.css';
import '../css/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import '../css/responsive.css';
import { NavLink } from 'react-router-dom';
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

function Header() {
  const baseurl = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <div className='top_header'>
        <div className='container'>
          <div className='top_main'>
            <div className='left_wrapper'>

              <ul>
                <li>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  <a href='tel:+91-7428894718'>+91-7428894718</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
                  <a href='mailto:info@acarelogistics.co.in'>info@acarelogistics.co.in</a>
                </li>
              </ul>
            </div>
            <div className='left_wrapper'>
            <ul class="social_ico">
        <li>
          <a href="#"><FontAwesomeIcon icon={faYoutube} /></a>
        </li>
        <li>
          <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
        </li>
        <li>
          <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
        </li>
      </ul>
              <ul>
                <li>
                  <a href='http://122.160.33.112/ACARETracking/' target='_blank'>Track Shipment</a>
                </li>
                <li>
                  <NavLink to={"/coming-soon"} activeClassName="active"> Partner Login </NavLink>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div className="navik-header header-transparent header-shadow">
        <div className="container">


          <div className="navik-header-container">


            <div className="logo" data-mobile-logo={process.env.PUBLIC_URL + "/images/acare.png" }  data-sticky-logo={process.env.PUBLIC_URL + "/images/logo.webp"}>
              <Link to="/" >
                <img src={process.env.PUBLIC_URL + "/images/acare.png"} alt="Acare Logo" />
              </Link>
            </div>


            <div className="burger-menu">
              <div className="line-menu line-half first-line"></div>
              <div className="line-menu"></div>
              <div className="line-menu line-half last-line"></div>
            </div>


            <nav className="navik-menu menu-caret submenu-top-border submenu-scale">
              <ul>

                <li>
                  <NavLink exact to="/" activeClassName="active">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" activeClassName="active">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/services" activeClassName="active">Services</NavLink>
                </li>
                <li>
                  <NavLink to="/Career" activeClassName="active">Career</NavLink>
                </li>
                <li>
                  <NavLink to="/clients" activeClassName="active">Clients</NavLink>
                </li>
                <li>
                  <NavLink to="/news" activeClassName="active">News</NavLink>
                </li>
                <li className="submenu-right">
                  <NavLink to="/contact-us" activeClassName="active">Contact Us</NavLink>
                </li>
                {/* <li><a href="#">Portfolio</a></li> */}
                {/* <ul>
                            <li><a href="#">Dropdown menu</a></li>
                            <li><a href="#">Dropdown menu</a></li>
                            <li><a href="#">Dropdown menu</a></li>
                            <li><a href="#">Dropdown menu</a></li>
                        </ul> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>

    </>

  );
}

export default Header;