import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Homeoffer() {
  const owlCarouselOptions = {
    items: 3,
    className: 'owl-theme',
    loop: true,
    nav: false,
    margin: 30,
    smartSpeed: 2000,
    dots: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      576: {
        items: 2,
        margin: 20,
      },
      768: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
      1200: {
        items: 3,
        margin: 30,
      },
    },
  };

  return (
    <section className='home_offers_section sp'>
      <div className='container'>
        <div className='offer_main'>
          <Row>
            <Col>
              <div className='main_title'>
                <h2>What We Offer</h2>
              </div>

              <div className='offer_carousel_main'>
                <OwlCarousel {...owlCarouselOptions}>
                  <div>
                   <a href="https://www.acarelogistics.co.in/services/acare-express">
                   <div className='offer_item' style={{ backgroundImage: 'url("https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/Acare-Express.jpg")' }}>
                      <div className='offer_icon'>
                        {/* Add your icon image element here */}
                      </div>
                      <div className='offer_content'>
                        <h3>Acare Express</h3>
                      </div>
                    </div>
                   </a>
                  </div>
                  <div>
                  <a href="https://www.acarelogistics.co.in/services/3pl-transport-management">
                    <div className='offer_item' style={{ backgroundImage: 'url("https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/3PL-ACTIVITIES.jpg")' }}>
                      <div className='offer_icon'>
                        {/* Add your icon image element here */}
                      </div>
                      <div className='offer_content'>
                        <h3>3PL Activities</h3>
                      </div>
                    </div>
                    </a>
                  </div>
                  <div>
                  <a href="https://www.acarelogistics.co.in/services/acare-warehousing">
                    <div className='offer_item' style={{ backgroundImage: 'url("https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/Acare-Warehousing.jpg")' }}>
                      <div className='offer_icon'>
                        {/* Add your icon image element here */}
                      </div>
                      <div className='offer_content'>
                        <h3>Acare Warehousing</h3>
                      </div>
                    </div>
                    </a>
                  </div>
                  <div>
                  <a href="https://www.acarelogistics.co.in/services/acare-premium">
                    <div className='offer_item' style={{ backgroundImage: 'url("https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/premium.jpg")' }}>
                      <div className='offer_icon'>
                        {/* Add your icon image element here */}
                      </div>
                      <div className='offer_content'>
                        <h3>Acare Premium</h3>
                      </div>
                    </div>
                    </a>
                  </div>

                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default Homeoffer;
