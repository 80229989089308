import React, { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import { Row, Col } from 'react-bootstrap';

const INDIA_TOPO_JSON = require('./india.topo.json');

const PROJECTION_CONFIG = {
  scale: 350,
  center: [78.9629, 22.5937]
};

const COLOR_RANGE = {
  AN: '#4CAF50', // Andaman and Nicobar Islands - Green (for lush forests)
  AP: '#FF5722', // Andhra Pradesh - Orange (for traditional art forms)
  AR: '#8B4513', // Arunachal Pradesh - Brown (for scenic landscapes)
  AS: '#FF9800', // Assam - Orange (for tea gardens)
  BR: '#9C27B0', // Bihar - Purple (for historical heritage)
  CH: '#F44336', // Chandigarh - Red (for modern architecture)
  CT: '#E91E63', // Chhattisgarh - Pink (for tribal culture)
  DN: '#FFEB3B', // Dadra and Nagar Haveli and Daman and Diu - Yellow (for beaches)
  DL: '#2196F3', // Delhi - Blue (for historic monuments)
  GA: '#FFC107', // Goa - Amber (for beaches and nightlife)
  GJ: '#4CAF50', // Gujarat - Green (for vibrant culture)
  HR: '#FF5722', // Haryana - Orange (for folk dances and traditions)
  HP: '#8BC34A', // Himachal Pradesh - Lime Green (for scenic mountains)
  JK: '#795548', // Jammu and Kashmir - Brown (for picturesque landscapes)
  JH: '#9E9E9E', // Jharkhand - Grey (for tribal heritage)
  KA: '#CDDC39', // Karnataka - Lime (for classical music and dance)
  KL: '#FF4081', // Kerala - Pink (for backwaters and cultural festivals)
  LA: '#607D8B', // Ladakh - Blue Grey (for serene landscapes)
  LD: '#F06292', // Lakshadweep - Pink (for coral reefs)
  MP: '#9C27B0', // Madhya Pradesh - Purple (for wildlife and heritage sites)
  MH: '#4CAF50', // Maharashtra - Green (for Bollywood and historical sites)
  MN: '#FF5722', // Manipur - Orange (for rich traditions and martial arts)
  ML: '#8BC34A', // Meghalaya - Lime Green (for scenic hills and waterfalls)
  MZ: '#FF9800', // Mizoram - Orange (for unique cultural practices)
  NL: '#F44336', // Nagaland - Red (for tribal traditions and festivals)
  OD: '#E91E63', // Odisha - Pink (for temples and traditional arts)
  PY: '#FFEB3B', // Puducherry - Yellow (for colonial architecture)
  PB: '#2196F3', // Punjab - Blue (for vibrant culture and cuisine)
  RJ: '#FFC107', // Rajasthan - Amber (for palaces and desert landscapes)
  SK: '#795548', // Sikkim - Brown (for Himalayan beauty)
  TN: '#CDDC39', // Tamil Nadu - Lime (for classical arts and temples)
  TS: '#FF4081', // Telangana - Pink (for historic monuments and cuisine)
  TR: '#607D8B', // Tripura - Blue Grey (for traditional music and dance)
  UP: '#9E9E9E', // Uttar Pradesh - Grey (for architectural wonders)
  UK: '#8BC34A', // Uttarakhand - Lime Green (for scenic mountains and spirituality)
  WB: '#FF5722', // West Bengal - Orange (for literature and cultural festivals)
};



const DEFAULT_COLOR = '#EEE';

const geographyStyle = {
  default: {
    outline: 'none'
  },
  hover: {
    fill: '#ccc',
    transition: 'all 250ms',
    outline: 'none'
  },
  pressed: {
    outline: '#000'
  }
};

function NetworkMain() {
  const [tooltipContent, setTooltipContent] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/263?_embed');
        const jsonData = await response.json();
        const acf = jsonData.acf;
        const states = acf.states;

        const heatMapData = states.map(state => ({
          id: state.state_id,
          state: state.state_name,
          value: 1, // Assign a fixed value instead of Math.random()
          cities: state.state_cities.map(city => city.state_cities_name)
        }));


        setData(heatMapData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const colorScale = scaleQuantile()
    .domain(data.map(d => d.value))
    .range(Object.values(COLOR_RANGE));
  const onMouseEnter = (geo, current) => {
    if (current) {
      const { state, cities } = current;
      let cityList = '';

      if (cities && cities.length > 0) {
        // Only generate the list if cities array has elements
        cityList = `<ul>${cities.map(city => (city ? `<li>${city}</li>` : '')).join('')}</ul>`;
      }

      setTooltipContent(`${state}: ${cityList}`);
    } else {
      // If current is null (no data for the hovered state), hide the tooltip
      setTooltipContent('');
    }
  };

  const onMouseLeave = () => {
    // Reset the tooltip when leaving the map area only if there is no content in the tooltip
    if (!tooltipContent) {
      const stateNameDiv = document.querySelector('.state_name');
      if (stateNameDiv) {
        stateNameDiv.style.display = 'none';
      }
    }
  };

  const stateNameDiv = document.querySelector('.state_name');
  if (stateNameDiv) {
    stateNameDiv.style.display = 'block';
  }


  return (
    <section className="sp ddy">
      <div className="full-width-height container">
        <h1 className="no-margin center">States and UTs</h1>
        <Row>
          <Col md="7">
            <ComposableMap
              projectionConfig={PROJECTION_CONFIG}
              projection="geoMercator"
              width={250}
              height={250}
              data-tip=""
              viewBox="60 10 200 250" // Set the viewBox attribute
            >
              <Geographies geography={INDIA_TOPO_JSON}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    const current = data.find(s => s.id === geo.id);
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={current ? COLOR_RANGE[current.id] || DEFAULT_COLOR : DEFAULT_COLOR}

                        style={geographyStyle}
                        onMouseEnter={() => onMouseEnter(geo, current)}
                        onMouseLeave={onMouseLeave}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
          </Col>
          <Col md="5">
            <div className="state_name">
              <h2 dangerouslySetInnerHTML={{ __html: tooltipContent }}></h2>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default NetworkMain;