import React from 'react'
import Header from '../header'
import Breadcum from '../breadcrumb'
import Footer from '../footer'

const ComingSoon = () => {
  return (
    <>
      
      {/* <section className="breadcrumb_main">
        <img src="https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/news-banner.jpg" alt="Featured Image"/>
        <div className="container">
          <div className="breadcrumb_inner">
            <h1>Coming Soon </h1>
          </div>
        </div>
      </section> */}
      <section className="Coming_soon sp">
        <div className="container">
          <h1>Coming Soon</h1>
        </div>
      </section>
    </>
  )
}

export default ComingSoon