import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function HomeChooseSection() {
  const [homeChooseContent, setChooseContent] = useState({
    homeChoseHeading: '',
    homeChoosecontents: '',
    homeChooseImage: '',
    fetchImageUrls: [],
  });

  useEffect(() => {
    const slug = window.location.pathname.split('/').filter(Boolean).pop();
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/133?_embed';

    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        const homeChoseHeading = data.home_choose_heading[0];
        const homeChoosecontents = data.home_choose_content[0];
        const homeChooseImageId = data.home_choose_image[0];
        const homeChooseUspImageIds = data.choose_usp_image;

        // Fetch the image URL using the `/media` endpoint for the main image
        fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${homeChooseImageId}`)
          .then((response) => response.json())
          .then((imageData) => {
            const homeChooseImage = imageData.source_url || imageData.title.rendered;
            const homeChooseImageAlt = imageData.alt_text || imageData.title.rendered;

            // Fetch the URLs for the other images
            const fetchImageUrls = homeChooseUspImageIds.map(async (imageId) => {
              try {
                const response = await fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`);
                const imageData = await response.json();
                return imageData.source_url || imageData.title.rendered;
              } catch (error) {
                console.error(error);
                return null;
              }
            });

            // Wait for all the images to be fetched before setting the state
            Promise.all(fetchImageUrls)
              .then((imageUrls) => {
                setChooseContent({
                  homeChoseHeading,
                  homeChoosecontents,
                  homeChooseImage,
                  homeChooseImageAlt,
                  fetchImageUrls: imageUrls,
                });
              })
              .catch((error) => console.error(error));
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <section className="home_choose_section sp">
        <div className="container">
          <Row>
            <Col md="6">
              <div className="home_choose_img">
                <img src={homeChooseContent.homeChooseImage} alt={homeChooseContent.homeChooseImageAlt} />
              </div>
            </Col>
            <Col md="6">
              <div className="home_choose_content">
                <div className="main_title">
                  <h2>{homeChooseContent.homeChoseHeading}</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: homeChooseContent.homeChoosecontents }}></div>
                <div className="choose_usp">
                  <Row>
                    {/* Map over the array of image URLs */}
                    {homeChooseContent.fetchImageUrls &&
                      homeChooseContent.fetchImageUrls.map((imageUrl, index) => (
                        <Col sm={4} key={index}>
                          <div className="choose_usp_item">
                            <div className="choose_img">
                              <img key={index} src={imageUrl} alt={`Image ${index}`} />
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default HomeChooseSection;
