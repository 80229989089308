import React from 'react'

export default function Thanku() {
  return (
    <>
<section class="thank_you sp">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="thank_you"> 
					<div class="thank_head">
						<p>Thank You for Contacting Acare Logoistics</p>
						<p>We have received your message and would like to thank you for writing to us. If your inquiry is urgent, please use the telephone number listed below to talk to one of our staff members.</p>
					</div>

					<div class="thank_head_two">
						<p>Call @ <a href="tel:7428894718">7428894718</a></p>
						<p>We will reply by email as soon as possible.</p>
						<p>Talk to you soon!!</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</section>
    </>
  )
}
