import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import Breadcum from "../breadcrumb";

const SingleNews = () => {
  const [newsContent, setNewsContent] = useState({});
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [newsGalleryImages, setNewsGalleryImages] = useState([]);
    const [newsData, setNewsData] = useState(null);
  // const [newsGalleryImages, setNewsGalleryImages] = useState([]);

  useEffect(() => {
    const slug = window.location.pathname.split("/").filter(Boolean).pop();
    const endpointUrl =
      "https://acarelogistics.co.in/panel/wp-json/wp/v2/news_posts?slug=" +
      slug +
      "&_embed";
    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        const newsTitle = data[0].title.rendered;
        const pageContent = data[0].content.rendered;
        const newsImageId = data[0].news_image[0]; // Assuming the news_image is an array with one ID

        fetch(
          `https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${newsImageId}`
        )
          .then((response) => response.json())
          .then((imageData) => {
            const singleNewsImage = imageData.source_url;
            setNewsContent({ singleNewsImage, newsTitle, pageContent });

            // Fetch news gallery images
            const newsGalleryImagesData = data[0].acf.news_gallery;
            const newsGalleryImagesUrls = newsGalleryImagesData.map(
              (imageData) => imageData.gallery_image
            );
            setNewsGalleryImages(newsGalleryImagesUrls);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(
      "https://acarelogistics.co.in/panel/wp-json/wp/v2/news_posts?per_page=2&_embed"
    )
      .then((response) => response.json())
      .then((data) => {
        const articles = data.map(async (article) => {
          const newsImageId = article.news_image[0]; // Assuming the news_image is an array with one ID
          const response = await fetch(
            `https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${newsImageId}`
          );
          const newsImageData = await response.json();
          const articleData = {
            id: article.id,
            title: article.title.rendered,
            date: article.date,
            slug: article.slug,
            newsImage: newsImageData.source_url, // Use the image source URL for each related article
          };
          return articleData;
        });

        Promise.all(articles).then((completedArticles) => {
          setRelatedArticles(completedArticles);
        });
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const slug = window.location.pathname.split("/").filter(Boolean).pop();
    const endpointUrl =
    "https://acarelogistics.co.in/panel/wp-json/wp/v2/news_posts?slug=" +
    slug +
    "&_embed";
    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        setNewsData(data[0]);

        // Fetch news gallery images URLs
        const galleryImageIds = data[0].news_gallery_image;
        const galleryImageUrlsPromises = galleryImageIds.map((imageId) =>
          fetch(
            `https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`
          ).then((response) => response.json())
        );

        Promise.all(galleryImageUrlsPromises).then((galleryImageUrlsData) => {
          const galleryImageUrls = galleryImageUrlsData.map(
            (imageData) => imageData.source_url
          );
          setNewsGalleryImages(galleryImageUrls);
        });
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Breadcum />
      <section className="single-article-main single-news sp">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
              <div className="single_article_content">
                <img src={newsContent.singleNewsImage} alt="News Image" />
                <div className="page_main_title">
                  <h2>{newsContent.newsTitle}</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: newsContent.pageContent }}></div>
              </div>

              {/* News Gallery */}
              {newsGalleryImages.length > 0 && (
                <div className="news-gallery">
                  {/* <h3>News Gallery</h3> */}
                  <div className="row">
                    {newsGalleryImages.map((image, index) => (
                      <div className="col-md-4 mb-4" key={index}>
                        <a href={image} data-fancybox="gallery">
                        <img src={image} alt={`Gallery Image ${index}`} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="related-article">
                {/* <h3>Latest News</h3> */}
                <ul>
                  {relatedArticles.map((article) => (
                    <li key={article.id}>
                      <a href={`/news/${article.slug}/`}>
                        <div className="realated-main">
                          <div className="related-img">
                            <img src={article.newsImage} alt="Related Article" /> {/* Display the news_image for each related article */}
                          </div>
                          <div className="related-title">
                            <p>{article.title}</p>
                            <h4>{article.date}</h4>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleNews;