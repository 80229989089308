import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function HomeVision() {
  const [homeAboutContent, setAboutContent] = useState({});

  useEffect(() => {
    const slug = window.location.pathname.split("/").filter(Boolean).pop();
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/133?_embed';

    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        const homeAboutHeading = data.home_about_heading[0];
        const homeAboutContents = data.home_about_content[0];
        const homeAboutButtonLink = data.home_about_button_link[0];
        const homeCountersNumbers = data.counters_numbers;
        const homeCountersTitle = data.conuters_title;
        const homeAboutImageId = data.home_about_image[0];

        const homeCounters = homeCountersNumbers.reduce((accumulator, currentValue, index) => {
          const title = homeCountersTitle[index];
          accumulator.push({ title, content: currentValue });
          return accumulator;
        }, []);

        // Fetch the image URL using the `/media` endpoint
        fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${homeAboutImageId}`)
          .then((response) => response.json())
          .then((imageData) => {
            const homeAboutImage = imageData.source_url;
            const altText = imageData.alt_text || imageData.title.rendered; // Use image title if alt text is not available

            // Set the state with all the fetched data
            setAboutContent({
              homeAboutHeading,
              homeAboutContents,
              homeCounters,
              homeAboutButtonLink,
              homeAboutImage,
              homeAboutImageAlt: altText,
            });
          });
      });
  }, []);

  return (
    <section className="home_mission sp">
      <div className="container">
        <Row>
          <Col md="6">
            <div className="mission_wrapper">
              <div className="main_title">
                <h2>{homeAboutContent.homeAboutHeading}</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: homeAboutContent.homeAboutContents }}></div>
              <Link to={homeAboutContent.homeAboutButtonLink} className="main_button">
                Know More
              </Link>
            </div>
          </Col>
          <Col md="6">
            <div className="Vision_image">
              <img src={homeAboutContent.homeAboutImage} alt={homeAboutContent.homeAboutImageAlt} />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default HomeVision;
