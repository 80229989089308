import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ServicesItem = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        let url = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/services/?_embed&per_page=100';
        axios.get(url).then((res) => {
            setPosts(res.data);
        });
    }, []);

    const extractParagraphs = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const paragraphs = doc.getElementsByTagName('p');
        let result = '';
        for (let i = 0; i < paragraphs.length; i++) {
            result += paragraphs[i].innerHTML;
        }
        return result;
    };

    return (
        <>
            <section className="news_wrapper sp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="news_main">
                                <div className="row">
                                    {posts.map((post, i) => (
                                        <div className="col-md-4" key={i}>
                                            <div className="services_item">
                                                <Link to={`/services/${post.slug}`}>
                                                    <div className="services_icon">
                                                        <img src='https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/footer-logo-1.png' alt="Service Image" />
                                                    </div>
                                                    <h3>{post.title.rendered}</h3>
                                                    <p>{extractParagraphs(post.content.rendered)}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesItem;
