import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Hometestimonial = () => {
  const [homeTestimonials, setHomeTestimonial] = useState([]);

  useEffect(() => {
    fetch('https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/133?_embed')
      .then(response => response.json())
      .then(data => {
        const testimonials = data.client_testimonial;
        const icons = data.client_icon;
        const designations = data.client_designation;

        Promise.all(
          icons.map(iconId =>
            fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${iconId}`)
              .then(response => response.json())
              .then(imageData => imageData.source_url)
              .catch(error => {
                console.error(`Failed to fetch image for testimonial with ID ${iconId}: ${error}`);
                return null;
              })
          )
        ).then(iconUrls => {
          const formattedTestimonials = testimonials.map((testimonial, index) => ({
            testimonials: testimonial,
            testimonial_icon: iconUrls[index],
            testimonial_designation: designations[index],
          }));
          setHomeTestimonial(formattedTestimonials);
        });
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <section className='home_testimonial sp'>
      <div className='container'>
        <div className='testimonial_main_wrapper'>
          <div className='main_title'>
            <h2>What Our Clients Say</h2>
          </div>
          <div className='testimonial_carousel_main'>
            {homeTestimonials.length > 0 ? (
              <OwlCarousel
                items={2}
                className='owl-theme'
                loop
                nav={false}
                margin={30}
                smartSpeed={2000}
                dots={true}
                autoplay={false}
                responsive={{
                  0: {
                    items: 1,
                    margin: 10,
                  },
                  576: {
                    items: 1,
                    margin: 20,
                  },
                  768: {
                    items: 2,
                    margin: 30,
                  },
                  992: {
                    items: 2,
                    margin: 30,
                  },
                  1200: {
                    items: 2,
                    margin: 30,
                  },
                }}
              >
                {homeTestimonials.map((item, i) => (
                  <div key={i}>
                    <div className='testoimonial_item'>
                      <div className='testimonial_content'>
                        <div dangerouslySetInnerHTML={{ __html: item.testimonials }}></div>
                      </div>
                      <div className='testimonial_Image'>
                        {item.testimonial_icon && (
                          <div className='user_icon'>
                            <img src={item.testimonial_icon} alt='User Icon' />
                          </div>
                        )}
                        <div className='testimonial_name'>
                          <div dangerouslySetInnerHTML={{ __html: item.testimonial_designation }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <div>No testimonials available.</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hometestimonial;
