import React from 'react'

export default function Notfound() {
  return (
    <section class="error sp" >
		<div class="container">
			<h2>
				404
			</h2>
			<p>
				The page you looking for does not exist or unavailable.
			</p>
      <a class="main_button" href="https://www.acarelogistics.co.in/">Go To Homepage</a>
                         
		</div>
</section>
  )
}
