import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Breadcum = () => {
  const location = useLocation();
  const [breadcrumbData, setBreadcrumbData] = useState({
    title: "",
    image: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpointUrl = `https://acarelogistics.co.in/panel/wp-json/wp/v2/pages?slug=${location.pathname}&_embed`;

        if (location.pathname.startsWith("/services/")) {
          const slug = location.pathname.split("/").pop();
          endpointUrl = `https://acarelogistics.co.in/panel/wp-json/wp/v2/services?slug=${slug}&_embed`;
        } else if (location.pathname.startsWith("/news/")) {
          const newsId = location.pathname.split("/").pop();
          endpointUrl = `https://acarelogistics.co.in/panel/wp-json/wp/v2/news_posts?slug=${newsId}&_embed`;
        } else if (location.pathname.startsWith("/blogs/")) {
          const blogSlug = location.pathname.split("/").pop();
          endpointUrl = `https://acarelogistics.co.in/panel/wp-json/wp/v2/blogs?slug=${blogSlug}&_embed`;
        }

        const response = await fetch(endpointUrl);
        const data = await response.json();

        if (data.length > 0) {
          setBreadcrumbData({
            title: data[0].title.rendered,
            image: data[0]._embedded["wp:featuredmedia"][0].source_url,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [location]);

  return (
    <>
      <section className="breadcrumb_main">
        <img
          src={breadcrumbData.image}
          alt={breadcrumbData.title || "image.title"}
        />
        <div className="container">
          <div className="breadcrumb_inner">
            <h1>{breadcrumbData.title}</h1>
            <ul>
              <li>
                <a href="#.">
                  Home <FontAwesomeIcon icon={faAngleRight} />
                </a>
              </li>
              <li>{breadcrumbData.title}</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcum;
