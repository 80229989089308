import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

const Careerform = () => {

  return (
    <>
      <section className="career-form sp">
        <Container>
          <Row>
            <Col md="12">
              <div class="page_main_title">
                <h2>Career Form</h2>
              </div>
            </Col>
            </Row>
            <Row class="career_row">
            <Col md="6">
              <div class="career_form">
              
                <Form action="https://acarelogistics.co.in/career-email.php" method="post">
                  <div class="form_field">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div class="form_field">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div class="form_field">
                    <Form.Control
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      pattern="[0-9]{10,12}"  // Pattern for max 12 digits
                      title="Please enter a valid phone number (up to 10 digits)"
                      required
                    />
                  </div>
                  <div class="form_field">
                    <Form.Control
                      type="file"
                      name="resume"
                      placeholder="resume"
                      required
                    />
                  </div>
                  <div class="form_field">
                    <button type="submit" className="main_button">
                      Submit Now
                    </button>
                  </div>
                </Form>
              
              </div>
            </Col>
            <Col md="6" class="career_image">
              <img src="https://acarelogistics.co.in/panel/wp-content/uploads/2023/08/Untitled-design-2.png" alt="career Image"/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Careerform;
