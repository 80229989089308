import Carousel from 'react-bootstrap/Carousel';
import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

function Herosection() {

  const [HomeSlider, setHomeSlider] = useState([]);

  useEffect(() => {
    const slug = window.location.pathname.split('/').filter(Boolean).pop();
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/133?_embed';

    fetch(endpointUrl)
      .then(response => response.json())
      .then(data => {
        const heroContent = data.slider_content;
        const heroImage = data.slider_image;
        const heroLink = data.button_link;

        const SliderContent = heroContent.reduce((Slider_item, currentValue, index) => {
          const slider_content = heroContent[index];
          const slider_image_id = heroImage[index];
          const slider_link = heroLink[index];

          // Fetch the image URL using the WordPress REST API
          fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${slider_image_id}`)
            .then(response => response.json())
            .then(imageData => {
              const slider_image = imageData.source_url;
              const alt_text = imageData.alt_text || imageData.title.rendered; // Use alt text if available, otherwise use image title
              Slider_item.push({ slider_content, slider_link, slider_image, alt_text, content: currentValue });
              setHomeSlider({ SliderContent });
            })
            .catch(error => {
              console.error(`Failed to fetch image for Slider with ID ${slider_image_id}: ${error}`);
              Slider_item.push({ slider_content, slider_link, content: currentValue });
            });
          return Slider_item;
        }, []);

        // console.log('Slider testimonials:', SliderContent);
      });
  }, []);

  return (
    <section className='hero_section'>
      <Carousel>
        {HomeSlider.SliderContent?.map((item, i) => (
          <Carousel.Item key={i}>
            <img src={item.slider_image} alt={item.alt_text} />
            <Carousel.Caption>
              <Container>
                <div dangerouslySetInnerHTML={{ __html: item.slider_content }}></div>
                {/* <div class="tmd"><Link to={item.slider_link} className='main_button'>Request A Quote</Link></div> */}
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default Herosection;
