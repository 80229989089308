import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';

function Homeclients() {
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get('https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/68?_embed')
      .then(response => {
        const clientsItems = response.data.clients_items;

        Promise.all(
          clientsItems.map(id =>
            axios
              .get(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${id}`)
              .then(attachment => {
                const imageURL = attachment.data.source_url;
                const altText = attachment.data.alt_text || attachment.data.title.rendered;
                return { imageURL, altText };
              })
              .catch(error => {
                console.error(`Failed to fetch image for client with ID ${id}: ${error}`);
                return null;
              })
          )
        ).then(imagesData => {
          setPageData(imagesData);
        });
      })
      .catch(error => console.error(error));
  }, []);

  const responsiveOptions = {
    0: {
      items: 1,
      margin: 10,
    },
    576: {
      items: 2,
      margin: 20,
    },
    768: {
      items: 3,
      margin: 30,
    },
    992: {
      items: 4,
      margin: 30,
    },
    1200: {
      items: 5,
      margin: 30,
    },
  };

  return (
    <section className='home_clients sp'>
      <div className='container'>
        <Row>
          <Col>
            <div className='clients_main_wrapper'>
              <div className='main_title'>
                <h2>A Glimpse Of Our Clients</h2>
              </div>
              <div className='clients_carousel_main'>
                {pageData.length > 0 ? (
                  <OwlCarousel
                    className='owl-theme'
                    items={5}
                    loop
                    nav={false}
                    margin={30}
                    smartSpeed={2000}
                    dots={true}
                    autoplay={true}
                    responsive={responsiveOptions}
                  >
                    {pageData.map((data, i) => (
                      <div key={i}>
                        <div className='clients_item'>
                          <div className='clients_icon'>
                            <img src={data.imageURL} alt={data.altText} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                ) : (
                  <div>No clients available.</div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Homeclients;
