import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { faEarthAmerica } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import TrackingDetails from './Pages/TrackingDetails';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

function TrackShipment() {
    const [formData, setFormData] = useState({
        name: ''
      });
    const [responseData, setResponseData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const handleSubmit = async (event) => {
        event.preventDefault();
        let response = await fetch(`http://acarelogistics.co.in/api/?order_no=${formData.name}`);

        response = await response.json();
        console.log(response);
        setResponseData(response);
      };
      const handleButtonClick = () => {
       console.log("tracking");
      };

      const isFormNameEmpty = formData.name.trim() === '';

    return (
      <>
      <div className='home_track_shipment'>
          <div className='container'>
            <Row>
                <Col>
                    <div className='track_shipment_main'>
                        <div className='main_title'>
                            <h2>Track your Shipment</h2>
                        </div>
                        <div className='shipment_form'>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12}>
                                    <div className='form_col'>
                                    <Form.Label>Enter your :</Form.Label>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Order No."
                                            defaultChecked
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Way Bill No."
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                        </div>
                                    ))}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='form_col trackShi'>
                                    <input type="text" name="name" value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })} placeholder='Enter your Order Number' />
                                   
                                    {/* <NavLink exact to={`/tracking-details?id=${formData.name}`} activeClassName="active" className="main_button">Search</NavLink> */}
                                    <NavLink exact to={`http://acarelogistics.co.in/tracking-details?id=${formData.name}`} activeClassName="active" className="main_button" onClick={formData.name ? undefined : (event) => { event.preventDefault(); setErrorMessage("Enter the shipment ID"); }}>Search</NavLink>
                                    </div>     
                                </Col>
                                <Col md={12}>
                                <div className={errorMessage ? "error-message" : ""}>{errorMessage}</div>
                                </Col>


                            </Row>
                            </Form>
                        </div>
                    </div>

                   
                                    

                </Col>
            </Row>
            </div>
        </div>
    </>
  )
}
export default TrackShipment;