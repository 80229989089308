import React from "react";
import Header from "../header";
import Footer from "../footer";
import Breadcum from "../breadcrumb";
import { BrowserRouter, Route, Routes, Link, Form } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AboutTabs from "../AboutTabs";
import Blogitem from "../Posts/Blogsitem";
const Blogs = () => {


  const [CoreTeamContent, setCoreContent] = useState([]);

  useEffect(() => {
    const slug = window.location.pathname.split('/').filter(Boolean).pop();
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages?slug=' + slug + '&_embed';

    fetch(endpointUrl)
      .then(response => response.json())
      .then(data => {
        const core_team_data = data[0].core_team_data;
        const core_team_image_id = data[0].core_team_image;

        // Fetch the image URLs using the `/media` endpoint for each management team image ID
        const fetchImageUrls = core_team_image_id.map(async (imageId) => {
          try {
            const response = await fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`);
            const imageData = await response.json();
            return imageData.source_url;
          } catch (error) {
            console.error(error);
          }
        });

        // Wait for all image URL requests to complete before updating state
        Promise.all(fetchImageUrls)
          .then((imageUrls) => {
            const CoreTeam = core_team_data.map((content, index) => {
              return {
                image: imageUrls[index],
                content: content,
              };
            });

            //console.log('Management Page:',managementTeam)
            setCoreContent(CoreTeam);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));

    //console.log('test',ManagementContent)
  }, []);


  return (
    <>
      <Breadcum />
      <AboutTabs />
      <Blogitem/>
    </>
  )
}
export default Blogs  ;