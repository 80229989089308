import React, { useState, useEffect } from "react";
import Breadcum from "../breadcrumb";
import axios from 'axios';

const Clients = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    axios.get('https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/68?_embed')
      .then(response => {
        const clientsItems = response.data.clients_items;
        const getImageData = async () => {
          const imageData = await Promise.all(clientsItems.map(async (id) => {
            try {
              const attachment = await axios.get(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${id}`);
              const altText = attachment.data.alt_text || attachment.data.title.rendered || '';
              return {
                url: attachment.data.source_url,
                altText: altText,
              };
            } catch (error) {
              console.error(error);
              return null;
            }
          }));
          setPageData(imageData.filter(item => item !== null)); // Filter out any null items
        };
        getImageData();
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Breadcum />
      <section className="Clients_main_wrapper sp">
        <div className="container">
          <div className="clients_main">
            <div className="page_main_title">
              <h2>Clients</h2>
            </div>
            <div className="clients_item_wrapper">
              <div className="row">
                {pageData && pageData.map((item, index) => (
                  <div className="col-md-3 col-sm-4 col-6" key={index}>
                    <div className="clients_items">
                      <img src={item.url} alt={item.altText} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Clients;
