import React from "react";
import Header from "../header";
import Footer from "../footer";
import Breadcum from "../breadcrumb";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
const ContactPage = () => {
    return (
        <>
            <Breadcum/>
                <section className="contact_wrapper sp">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_main_title">
                                    <h2>Contact Us</h2>
                                    <p>Don’t hesitate to contact us with any questions or feedback – we’d love to hear from you!</p>
                                </div>
                                <div className="contact_main">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="contact_form">
                                                <Form action="https://acarelogistics.co.in/send_email.php" method="post">
                                                <Row>
                        <Col md={12}>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name"
                                required
                            />
                        </Col>
                        <Col md={12}>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                            />
                        </Col>
                        <Col md={12}>
                            <Form.Control
                                type="tel"
                                name="phone"
                                placeholder="Phone"
                                pattern="[0-9]{10,12}"  // Pattern for max 12 digits
                                title="Please enter a valid phone number (up to 10 digits)"
                                required
                            />
                        </Col>
                        <Col md={12}>
                            <Form.Control
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                required
                            />
                        </Col>
                        <Col md={12}>
                            <Form.Control
                                as="textarea"
                                name="comment"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                required
                            />
                        </Col>
                    </Row>
                                                    <button type="submit" className="main_button">Submit Now</button>
                                                    </Form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="contact_image">
                                                <img src="https://acarelogistics.co.in/panel/wp-content/uploads/2023/05/contact.jpg"/>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="map_image">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.8797476253144!2d-79.69095228449893!3d43.754593879118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3ce497910991%3A0x400167d051e5f17f!2s100%20Exchange%20Dr%2C%20Brampton%2C%20ON%20L6S%200C8%2C%20Canada!5e0!3m2!1sen!2sin!4v1677671263399!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </div>
                        </div>
                    </div>
                </section> */}
     </>
    )
  }
  export default ContactPage;