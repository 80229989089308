import React from "react";
import Header from "../header";
import Footer from "../footer";
import Breadcum from "../breadcrumb";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams ,Link} from 'react-router-dom';
const SingleServices = () => {


    const { slug } = useParams();
    const [SingleService, setSingleService] = useState({});
    
    useEffect(() => {
      axios.get(`https://acarelogistics.co.in/panel/wp-json/wp/v2/services?slug=${slug}&_embed`)
        .then(res => {
            setSingleService(res.data[0]);
         // console.log('Single Service', res.data[0].content?.rendered);
        })
        .catch(err => {
          console.log('error:', err.message);
        });
    }, [slug]);
    
    return (
        <>
            <Breadcum/>

            <section className="single-services-main sp">
                <div className="container">
                        <div className="row">
                        <div className="col-md-12 col-lg-12zz">
                            <div className="single_article_content">
                            <div dangerouslySetInnerHTML={{ __html: SingleService.content?.rendered }}></div>
                            </div>
                        </div>
                           
                        </div>
                </div>
             </section>
       
     </>
    )
  }
  export default SingleServices;