import React, { useState, useEffect } from "react";
import axios from 'axios';
import Breadcum from "../breadcrumb";
import AboutTabs from "../AboutTabs";

const About = () => {
    const [aboutPage, setPage] = useState(null);

    useEffect(() => {
        axios
            .get(`https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/54?_embed`)
            .then((response) => {
                setPage(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const ResponseSrc = aboutPage?.overview_above_content;
    const overview_bottom_content = aboutPage?.overview_bottom_text;
    const overview_above_image_id = aboutPage?.['overview_above_image'];
    const overview_bottom_image_id = aboutPage?.['overview_bottom_image'];

    async function getOverviewTopImageInfo() {
        try {
            const imageInfo = await wpGetAttachmentImageInfo(overview_above_image_id);
            return imageInfo;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function getOverviewBottomImageInfo() {
        try {
            const imageInfo = await wpGetAttachmentImageInfo(overview_bottom_image_id);
            return imageInfo;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function wpGetAttachmentImageInfo(attachmentId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${attachmentId}`)
                .then((response) => {
                    const imageInfo = {
                        alt: response.data.alt_text || response.data.title.rendered, // Use title as fallback
                        url: response.data.source_url
                    };
                    resolve(imageInfo);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const [overviewTopImageInfo, setOverviewTopImageInfo] = useState(null);
    useEffect(() => {
        getOverviewTopImageInfo().then(info => setOverviewTopImageInfo(info));
    }, [overview_above_image_id]);

    const [overviewBottomImageInfo, setOverviewBottomImageInfo] = useState(null);
    useEffect(() => {
        getOverviewBottomImageInfo().then(info => setOverviewBottomImageInfo(info));
    }, [overview_bottom_image_id]);

    return (
        <>
            <Breadcum />
            <AboutTabs />
            <section className="about_main_wrapper sp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about_inner_main">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="about_right_content">
                                            <div className="about_image">
                                                {overviewTopImageInfo && (
                                                    <img src={overviewTopImageInfo.url} alt={overviewTopImageInfo.alt} />
                                                )}
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: overview_bottom_content }}></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about_content">
                                            <div className="page_main_title">
                                                <h2>Overview</h2>
                                                <h4>MAKING THE IMPOSSIBLE, POSSIBLE</h4>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: ResponseSrc }}></div>
                                            <div className="image">
                                                {overviewBottomImageInfo && (
                                                    <img src={overviewBottomImageInfo.url} alt={overviewBottomImageInfo.alt} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
