import React, { useState, useEffect } from "react";
import Breadcum from "../breadcrumb";
import AboutTabs from "../AboutTabs";

const ManagementTeam = () => {
  const [managementContent, setManagementContent] = useState([]);

  useEffect(() => {
    const slug = window.location.pathname.split('/').filter(Boolean).pop();
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages?slug=' + slug + '&_embed';

    fetch(endpointUrl)
      .then(response => response.json())
      .then(data => {
        const managementTeamContent = data[0].management_team_content;
        const managementTeamImageIds = data[0].management_team_image;

        // Fetch the image URLs using the `/media` endpoint for each management team image ID
        const fetchImageUrls = managementTeamImageIds.map(async (imageId) => {
          try {
            const response = await fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`);
            const imageData = await response.json();
            let altText = imageData.alt_text || ''; // Initialize alt text with empty string

            // If alt text is not available, use the title as alt text
            if (!altText) {
              altText = imageData.title.rendered || ''; // Use title as alt text
            }

            return {
              image: imageData.source_url,
              altText: altText,
            };
          } catch (error) {
            console.error(error);
            return null;
          }
        });

        // Wait for all image URL requests to complete before updating state
        Promise.all(fetchImageUrls)
          .then((imageDataArray) => {
            const managementTeam = managementTeamContent.map((content, index) => {
              const imageData = imageDataArray[index];
              return {
                image: imageData ? imageData.image : '', // Use an empty string if image data is not available
                altText: imageData ? imageData.altText : '', // Use an empty string if alt text is not available
                content: content,
              };
            });

            setManagementContent(managementTeam);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Breadcum />
      <AboutTabs />
      <section className="about_main_wrapper management_wrapper_main sp">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_inner_main">
                <div className="page_main_title">
                  <h2>Management &nbsp; Team</h2>
                </div>

                {managementContent.map((member, index) => (
                  <div className="manangement_item" key={index}>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="management_team_image">
                          <img src={member.image} alt={member.altText} />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="management_team_content">
                          <div dangerouslySetInnerHTML={{ __html: member.content }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ManagementTeam;
