import React, { useEffect,useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from "../header";
import Footer from "../footer";


function TrackingDetails(){
    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Function to extract the id parameter from the URL
        const getIdFromURL = () => {
          const urlParams = new URLSearchParams(window.location.search);
          return urlParams.get('id');
        };
    
        // API call to fetch data based on the id parameter
        const fetchData = async () => {
          const id = getIdFromURL();
          if (id) {
            try {
              const response = await fetch(`http://acarelogistics.co.in/api/?order_no=${id}`);
              const data = await response.json();
              console.log(data);
              setResponseData(data);
              setLoading(false);
              // Do something with the response data
            } catch (error) {
              console.error('Error:', error);
            }
          }
        };
    
        fetchData();
      }, []);

      const handlePrintPdf = () => {
        // Logic to download the PDF file
        if (responseData) {
            window.print('.trackingOuter');
        }
      };

    return (
        <>
        {/* <Header/> */}
            <div className='tracking-detail'>
                <div className='container'>
                    <Row>
                        <Col md={12}>
                            <div className='trackingOuter'>
                            <h4>Summary Results</h4>
                            <div className="shipmentSummary">
                                <p>Shipment Summary</p>

                                {/* <div class="shipment-print">
										<p class="printPdf" onClick={handlePrintPdf}>Print</p>
									</div> */}
                        
                            </div>
                            {loading ? (
                  <div className="loader">Loading...</div> // Show the loader when the data is being fetched
                ) : responseData && responseData.Status !== "Fail" ? (
                  // Render the data when it is available and not failed

                                <div className="apiDataOuter">

                               
                            <div className="apiData">
                            <div className="apiDataLeft">
                                <div className="api1">
                                    <p><strong>CNNo: </strong></p>
                                    <div className="api1DataRt">{responseData?.CNNo}</div>
                                </div>

                                {/* <div className="api1">
                                    <p><strong>GPSStatus</strong></p>
                                    <div className="api1DataRt">{responseData?.GPSStatus}</div>
                                </div> */}

                                <div className="api1">
                                    <p><strong>CNStatus: </strong></p>
                                    <div className="api1DataRt">{responseData?.CNStatus}</div>
                                </div>

                                

                                <div className="api1">
                                    <p><strong>CNDate: </strong></p>
                                    <div className="api1DataRt">{responseData?.CNDate}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Consignor: </strong></p>
                                    <div className="api1DataRt">{responseData?.Consignor}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Consignee: </strong></p>
                                    <div className="api1DataRt">{responseData?.Consignee}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>From: </strong></p>
                                    <div className="api1DataRt">{responseData?.From}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>To: </strong></p>
                                    <div className="api1DataRt">{responseData?.To}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>NOP: </strong></p>
                                    <div className="api1DataRt">{responseData?.NOP}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Weight: </strong></p>
                                    <div className="api1DataRt">{responseData?.Weight}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Freight Mode: </strong></p>
                                    <div className="api1DataRt">{responseData?.FreightMode}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Invoice No: </strong></p>
                                    <div className="api1DataRt">{responseData?.InvoiceNo}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Invoice Value: </strong></p>
                                    <div className="api1DataRt">{responseData?.InvoiceValue}</div>
                                </div>

                                <div className="api1">
                                    <p><strong>Quantity: </strong></p>
                                    <div className="api1DataRt">{responseData?.Quantity}</div>
                                </div>

                                
                                </div>

                                <div className="apiDataRight">
                                <div className="api1 apiPod">
                                    <p><strong>POD: </strong></p>
                                    <div className="api1DataRt"><img src={responseData?.POD} /></div>
                                </div>
                                </div>

                            </div>
                            <div className="shipmentSummary"><p>Shipment Tracking History</p></div>

                            <div className="track-hist">
							
							<div className="track-hist-box track_color">
								<p><img src="https://acarelogistics.co.in/panel/wp-content/uploads/2023/06/at-destination-1.png" alt="" />Booked</p>
							</div>
							<div className="track-hist-box">
								<p><img src="https://acarelogistics.co.in/panel/wp-content/uploads/2023/06/delivered.png" alt="" />Delivered</p>
							</div>
							
						    </div>

                            <div class="table-data-all">
						<table id="table-data-all-api">
							<thead>
								<tr>
									<th>Date</th>
									<th>Activity</th>
									<th>From</th>
									<th>To</th>
								</tr>
							</thead>
							<tbody>
                                <tr>
                                    <td>{responseData?.CNDate}</td>
                                    <td>BOOKED</td>
                                    <td>{responseData?.From}</td>
                                    <td>{responseData?.To}</td>
                                </tr>
                                <tr>
                                    <td>{responseData?.CNStatus}</td>
                                    <td>DELIVERED </td>
                                    <td>{responseData?.From}</td>
                                    <td>{responseData?.To}</td>
                                </tr>
                            </tbody>
						</table>
					</div>

                            </div>

                             ) : (
                                <p className="noDataFound">No Data Found.</p>
                              )}

                            </div>
                        </Col>


                    </Row>
                </div>
            </div>

            

        {/* <Footer/> */}
        </>
    )
}

export default TrackingDetails;