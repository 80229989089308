import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'; // Import the format function
import { enUS } from 'date-fns/locale'; // Import locale

const NewsItem = () => {
    const [posts, setPosts] = useState([]);
    const [images, setImages] = useState({});

    useEffect(() => {
        let url = `https://acarelogistics.co.in/panel/wp-json/wp/v2/blogs/?_embed&per_page=100`;
        axios.get(url).then((res) => {
            setPosts(res.data);
        });
    }, []);

    useEffect(() => {
        posts.forEach((post) => {
            const imageId = post.blog_image;
            axios.get(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${imageId}`)
                .then(res => {
                    const imageUrl = res.data.source_url;
                    setImages(images => ({ ...images, [imageId]: imageUrl }));
                });
        });
    }, [posts]);

    return (
        <section className="news_wrapper sp">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="news_main">
                            <div className="page_main_title">
                                <h2>Latest Blogs</h2>
                            </div>
                            <div className="row">
                                {posts.map((post, i) => {
                                    const imageId = post.blog_image;
                                    const postDate = new Date(post.date); // Parse the date
                                    const formattedDate = format(postDate, 'MMMM yyyy', { locale: enUS }); // Format the date
                                    return (
                                        <div className="col-md-4" key={i}>
                                            <div className="new_item">
                                                <Link to={`/blogs/${post.slug}`}>
                                                    <div className="news_image">
                                                        {images[imageId] && <img src={images[imageId]} alt={post.title.rendered} />}
                                                    </div>
                                                    <h3>{post.title.rendered}</h3>
                                                    <div className="date">
                                                        <h5>{formattedDate}</h5>
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsItem;
