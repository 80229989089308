import React, { useState, useEffect } from "react";
import axios from 'axios';
import Breadcum from "../breadcrumb";
import AboutTabs from "../AboutTabs";

const Vision = () => {
    const [missionContent, setMissionContent] = useState({});

    useEffect(() => {
        const slug = window.location.pathname.split('/').filter(Boolean).pop();
        const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages?slug=' + slug + '&_embed';

        fetch(endpointUrl)
            .then(response => response.json())
            .then(data => {
                const missionTagline = data[0].mission_tagline;
                const missionImageId = data[0].mission_image;
                const missionPointerTagline = data[0].pointers_tagline;
                const missionPointers = data[0].mission_pointers;
                const missionMainContent = data[0].mission_main_content;

                // Fetch the image data including alt text and title
                fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${missionImageId}`)
                    .then(response => response.json())
                    .then(imageData => {
                        const missionImage = imageData.source_url;
                        let missionAltText = imageData.alt_text || ''; // Initialize alt text with empty string

                        // If alt text is not available, use the title as alt text
                        if (!missionAltText) {
                            missionAltText = imageData.title.rendered || ''; // Use title as alt text
                        }

                        // Set the state with all the fetched data including alt text and title
                        setMissionContent({
                            missionTagline,
                            missionMainContent,
                            missionImage,
                            missionPointerTagline,
                            missionPointers,
                            missionAltText,
                        });
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }, []);

    return (
        <>
            <Breadcum/>
            <AboutTabs/>
            <section className="vision_wrapper sp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="vision_main">
                                <div className="page_main_title">
                                    <h2>Our Vision</h2>
                                </div>
                                <p>{missionContent.missionTagline}</p>
                                <div className="vision_image">
                                    <img src={missionContent.missionImage} alt={missionContent.missionAltText} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vision_pointers sp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="page_main_title">
                                <h2>{missionContent.missionPointerTagline}</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pointers_content">
                                <div dangerouslySetInnerHTML={{ __html: missionContent.missionPointers }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vision_content sp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="vision_main_content">
                                <div dangerouslySetInnerHTML={{ __html: missionContent.missionMainContent }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Vision;
