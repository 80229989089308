import React from "react";
import Header from "../header";
import Footer from "../footer";
import { Link } from "react-router-dom";
import NewsItem from "../Posts/NewsItems";
import Breadcum from "../breadcrumb";
const News = () => {
    return (
        <>
            <Breadcum />
             <NewsItem/>
     </>
    )
  }
  export default News;