import React from "react";
import Header from "../header";
import Footer from "../footer";
import Breadcum from "../breadcrumb";
import { Link } from "react-router-dom";
import ServicesItem from "../Posts/ServicesItem";
const Services = () => {
    return (
        <>
            <Breadcum/>
           <ServicesItem/>  
     </>
    )
  }
  export default Services;