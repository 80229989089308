import './App.css';
import { Route, Router, Routes } from 'react-router';
import Homepage from './components/Pages/Homepage';
import About from './components/Pages/About';
import Clients from './components/Pages/Clients';
import ManagementTeam from './components/Pages/ManagementTeam';
import News from './components/Pages/News';
import SingleNews from './components/Single/Single-News';
import ContactPage from './components/Pages/Contact';
import Services from './components/Pages/Services';
import SingleServices from './components/Single/Single-Services';
import CoreTeam from './components/Pages/Core-team';
import Vision from './components/Pages/VisionMission';
import Misson from './components/Pages/Mision';
import Netwotk from './components/Pages/Network';
import Career from './components/Pages/Career';
import TrackShipment from './components/track-shipment';
import TrackingDetails from './components/Pages/TrackingDetails';
import Header from "../src/components/header";
import Footer from "../src/components/footer";
import ComingSoon from './components/Pages/ComingSoon';
import Blogs from './components/Pages/Blogs';
import SingleBlogs from './components/Single/Single-blogs';
import { useEffect } from 'react';
import Thanku from './components/Pages/Thanku';
import Notfound from './components/Pages/Notfound';

function App() {

  return (
    <div className="App">
      <>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/mission" element={<Misson />} />
          <Route path="/management-team" element={<ManagementTeam />} />
          <Route path="/core-team" element={<CoreTeam />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:slug" element={<SingleNews />} />
          <Route path="/blogs/:slug" element={<SingleBlogs/>} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:slug" element={<SingleServices />} />
          <Route path="/contact-us" element={<ContactPage />} />
          {/* <Route path="/network" element={<Netwotk />} /> */}
          <Route path="/career" element={<Career />} />
          <Route path="/tracking-details" element={<TrackingDetails />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/thank-you" element={<Thanku/>} />
          <Route path="*" element={<Notfound/>} />
        </Routes>
        <Footer />
      </>
    </div>
  );
}

export default App;
