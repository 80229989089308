import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation, Link } from 'react-router-dom';

const AboutTabs = () => {
  const location = useLocation();

  const isActiveTab = (path) => {
    return location.pathname.includes(path) ? 'active' : '';
  };

  return (
    <>
      <div className="about_main_tabs">
        <ul>
          <li>
            <Link to="/about-us" className={isActiveTab('/about-us')}>
              Overview <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </li>
          <li>
            <Link to="/mission" className={isActiveTab('/mission')}>
              Mission <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </li>
          <li>
            <Link to="/vision" className={isActiveTab('/vision')}>
              Vision <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </li>
          <li>
            <Link to="/core-team" className={isActiveTab('/core-team')}>
            CORE TEAM <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </li>
          <li>
            <Link to="/management-team" className={isActiveTab('/management-team')}>
            MD's desk<FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </li>
          <li>
          <Link to="/blogs" className={isActiveTab('/blogs')}>
              Blogs <FontAwesomeIcon icon={faArrowDown} />
          </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AboutTabs;
