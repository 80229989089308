import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function HomeNetworks() {
  const [homeNetworkContent, setHomeNetworkContent] = useState({});

  useEffect(() => {
    const endpointUrl = 'https://acarelogistics.co.in/panel/wp-json/wp/v2/pages/133?_embed';

    fetch(endpointUrl)
      .then(response => response.json())
      .then(data => {
        const homeNetworkHeading = data.home_network_heading[0];
        const homeNetworkLink = data.home_network_link[0];
        const homeNetworkContent = data.home_network_content[0];
        const homeNetworkImageId = data.home_network_image[0];

        // Fetch the image URL using the `/media` endpoint
        fetch(`https://acarelogistics.co.in/panel/wp-json/wp/v2/media/${homeNetworkImageId}`)
          .then(response => response.json())
          .then(imageData => {
            const homeNetworkImage = imageData.source_url;

            // Set the state with all the fetched data
            setHomeNetworkContent({ homeNetworkHeading, homeNetworkContent, homeNetworkLink, homeNetworkImage });
          });
      });
  }, []);

  return (
    <>
      <section className="home_networks sp">
        <div className="container">
          <Row>
            <Col md={5}>
              <div className="networks_main_Image">
                <img src={homeNetworkContent.homeNetworkImage} alt="Network Image" />
              </div>
            </Col>
            <Col>
              <div className="networks_main_content">
                <div className="main_title">
                  <div dangerouslySetInnerHTML={{ __html: homeNetworkContent.homeNetworkHeading }}></div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: homeNetworkContent.homeNetworkContent }}></div>
                {/* <Link to='/network' className="main_button">Know More</Link> */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default HomeNetworks;
